export const SET_CURRENT_SCREEN = "SET_CURRENT_SCREEN";
export const SET_BOND_DETAILS = "SET_BOND_DETAILS";
export const SET_ORDERS = "SET_ORDERS";

export const SET_REGISTERED_ON_GRIP = "SET_REGISTERED_ON_GRIP";
export const SET_REGISTERED_ON_YUBI = "SET_REGISTERED_ON_YUBI";

export const GET_USER_CONSENT_STATUS = "GET_USER_CONSENT_STATUS";
export const GET_USER_CONSENT_STATUS_SUCCESS = "GET_USER_CONSENT_STATUS_SUCCESS";
export const GET_USER_CONSENT_STATUS_FAILURE = "GET_USER_CONSENT_STATUS_FAILURE";

export const REGISTER_USER_TO_GRIP = "REGISTER_USER_TO_GRIP";
export const REGISTER_USER_TO_GRIP_SUCCESS = "REGISTER_USER_TO_GRIP_SUCCESS";
export const REGISTER_USER_TO_GRIP_FAILURE = "REGISTER_USER_TO_GRIP_FAILURE";

export const GET_ALL_GRIP_BONDS = "GET_ALL_GRIP_BONDS";
export const GET_ALL_GRIP_BONDS_SUCCESS = "GET_ALL_GRIP_BONDS_SUCCESS";
export const GET_ALL_GRIP_BONDS_FAILURE = "GET_ALL_GRIP_BONDS_FAILURE";

export const GET_BOND_DETAILS = "GET_BOND_DETAILS";
export const GET_BOND_DETAILS_SUCCESS = "GET_BOND_DETAILS_SUCCESS";
export const GET_BOND_DETAILS_FAILURE = "GET_BOND_DETAILS_FAILURE";

export const GET_GRIP_REDIRECTION_LINK = "GET_GRIP_REDIRECTION_LINK";
export const GET_GRIP_REDIRECTION_LINK_SUCCESS = "GET_GRIP_REDIRECTION_LINK_SUCCESS";
export const GET_GRIP_REDIRECTION_LINK_FAILURE = "GET_GRIP_REDIRECTION_LINK_FAILURE";

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const REDEEM_NOW = "REDEEM_NOW";
export const REDEEM_NOW_SUCCESS = "REDEEM_NOW_SUCCESS";
export const REDEEM_NOW_FAILURE = "REDEEM_NOW_FAILURE";

export const RESET_REDEEM = "RESET_REDEEM";

export const YUBI_REDIRECT = "YUBI_REDIRECT";
export const YUBI_REDIRECT_SUCCESS = "YUBI_REDIRECT_SUCCESS";
export const YUBI_REDIRECT_FAILURE = "YUBI_REDIRECT_FAILURE";
export const IS_BACK = "IS_BACK";

export const BACK_FROM_BONDS = "BACK_FROM_BONDS";
export const SET_USER_BASIC_DETAILS = "SET_USER_BASIC_DETAILS";

export const GET_WEALTH_WAITLIST = "GET_WEALTH_WAITLIST";
export const GET_WEALTH_WAITLIST_SUCCESS = "GET_WEALTH_WAITLIST_SUCCESS";
export const GET_WEALTH_WAITLIST_FAILURE = "GET_WEALTH_WAITLIST_FAILURE";

export const ADD_WEALTH_WAITLIST = "ADD_WEALTH_WAITLIST";
export const ADD_WEALTH_WAITLIST_SUCCESS = "ADD_WEALTH_WAITLIST_SUCCESS";
export const ADD_WEALTH_WAITLIST_FAILURE = "ADD_WEALTH_WAITLIST_FAILURE";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const PRIORITY_LIST_FORM_RESPONSES = "PRIORITY_LIST_FORM_RESPONSES";

export const GET_BONDS_LIST = "GET_BONDS_LIST";
export const GET_BONDS_LIST_SUCCESS = "GET_BONDS_LIST_SUCCESS";
export const GET_BONDS_LIST_FAILURE = "GET_BONDS_LIST_FAILURE";

export const SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT = "SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT";
export const SET_JOURNEY_END_PAGE_APPEARANCE_COUNT = "SET_JOURNEY_END_PAGE_APPEARANCE_COUNT";
export const SET_USER_KYC_MODAL_APPEARANCE_COUNT = "SET_USER_KYC_MODAL_APPEARANCE_COUNT";
export const SET_OFFER_BANNER_APPEARANCE_COUNT = "SET_OFFER_BANNER_APPEARANCE_COUNT";

export const VERIFY_ACCOUNT_PAGE_SHOWN = "VERIFY_ACCOUNT_PAGE_SHOWN";
export const JOURNEY_END_PAGE_SHOWN = "JOURNEY_END_PAGE_SHOWN";
export const USER_KYC_MODAL_SHOWN = "USER_KYC_MODAL_SHOWN";
export const OFFER_BANNER_SHOWN = "OFFER_BANNER_SHOWN";

export const SET_BOND_ASSET_ID = "SET_BOND_ASSET_ID";

export const GENERATE_WEALTH_OTP = "GENERATE_WEALTH_OTP";
export const GENERATE_OTP_SUCCESS = "GENERATE_OTP_SUCCESS";
export const GENERATE_OTP_FAILURE = "GENERATE_OTP_FAILURE";
export const RESET_WEALTH_OTP = "RESET_WEALTH_OTP";
export const SET_LOADER_FALSE = "SET_LOADER_FALSE";

export const GET_PARTNERS_DETAILS = "GET_PARTNERS_DETAILS";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILURE = "GET_PARTNERS_FAILURE";

export const VERIFY_WEALTH_OTP = "VERIFY_WEALTH_OTP";
export const WEALTH_VERIFY_OTP_SUCCESS = "WEALTH_OTP_SUCCESS";
export const WEALTH_VERIFY_OTP_FAILURE = "WEALTH_OTP_FAILURE";
export const RESET_VERIFY_OTP_DATA = "RESET_OTP_DATA";

export const SET_SELECTED_BOND = "SET_SELECTED_BOND";

export const GET_WEALTH_OFFERS = "GET_WEALTH_OFFERS";
export const GET_WEALTH_OFFERS_SUCCESS = "GET_WEALTH_OFFERS_SUCCESS";
export const GET_WEALTH_OFFERS_FAILURE = "GET_WEALTH_OFFERS_FAILURE";
export const GET_BANK_LIST = "GET_BANK_LIST";
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS";
export const GET_BANK_LIST_FAILURE = "GET_BANK_LIST_FAILURE";

export const GET_BANK_ADDRESS = "GET_BANK_ADDRESS";
export const GET_BANK_ADDRESS_SUCCESS = "GET_BANK_ADDRESS_SUCCESS";
export const GET_BANK_ADDRESS_FAILURE = "GET_BANK_ADDRESS_FAILURE";

export const TRIGGER_PANNY_DROP = "TRIGGER_PANNY_DROP";
export const TRIGGER_PANNY_DROP_SUCCESS = "TRIGGER_PANNY_DROP_SUCCESS";
export const TRIGGER_PANNY_DROP_FAILURE = "TRIGGER_PANNY_DROP_FAILURE";
