/* eslint-disable camelcase */
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const clevertapEventApiCall = async ({ auth_token, eventData, partner_code = "", jwt_token = "" }) => {
  try {
    const device_id = localStorage.getItem("device_id");
    const headers = { auth_token, device_id, source: "web", partner_code, jwt_token };
    if (device_id) {
      delete headers.partner_code;
    }
    const response = await axios.put(`${BASE_URL}magneto/clevertap/send-event-from-web`, eventData, { headers });
    return response;
  } catch (error) {
    return error;
  }
};