import { ARMY_LINK, CITY_WISE_INSTANT_PERSONAL_LOANS, LOAN_CALCULATOR, NEWS_ROOM_LINK } from "../../../utils/constant";

const { pathname } = window.location;
const getLastPathName = pathname.lastIndexOf("/");
const getFinalPathname = pathname.substring(getLastPathName + 1);
export const PATH_WITH_FOOTER = [
  "/",
  "/signup",
  "/about-us",
  "/rewards",
  "/why-us",
  // "/careers",
  "/partners",
  "/faq",
  "/contactus",
  "/customer-services",
  "/customer-service-list-second",
  "/login",
  "/women-card",
  "/grievance-redressal-mechanism",
  "/terms-and-conditions",
  "/privacy-policy",
  "/instant-personal-loans",
  "/privacy-policies",
  `/customer-services/${getFinalPathname}`,
  "/instant-personal-loans-in-delhi",
  "/founders",
  // "/careers",
  "/women-card",
  "/newsroom",
  "/sitemap",
  "/referral-program",
  "/refer-and-earn",
  "/fcr",
  `${CITY_WISE_INSTANT_PERSONAL_LOANS}/${getFinalPathname}`,
  ARMY_LINK,
  NEWS_ROOM_LINK,
  LOAN_CALCULATOR,
  "/small-loans",
  "/private-loans",
  "/unsecured-loans",
  "/wealth-home"
];

export const PATH_WITH_DIFFERENT_BG_COLOR = ["/wealth-home"];
