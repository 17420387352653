import React from "react";
import img1 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Wallet.svg";
import img2 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Loan.svg";
import img3 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Income.svg";
import img4 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Travel.svg";
// import img5 from "../../assets/images/InsuranceNewHomepage/insurance Icons/OTT.svg";
// import img7 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Deals.svg";
// import img8 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Pocket.svg";
import img9 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Cancer.svg";
// import img10 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Child.svg";
// import img11 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Home.svg";
import img12 from "../../assets/images/InsuranceNewHomepage/insurance Icons/Motor.svg";
import img13 from "../../assets/images/InsuranceNewHomepage/insurance Icons/UPI.svg";
// import img14 from "../../assets/images/InsuranceNewHomepage/insurance Icons/HomeApp.svg";
import popularTag from "../../assets/images/InsuranceNewHomepage/popularTag.png";

function InsuranceItems({ handleItemClick, handleItemClickWithoutOptions }) {
  return (
    <div className="insurance_items">
      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("wallet", "Wallet Protection");
        }}
      >
        <div className="ins_white_div">
          <img src={popularTag} className="popularImg"></img>
          <img src={img1} className="ins_prop_img"></img>
          <span className="ins_prop">
            Wallet <br></br>Protection
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹599/-pa*</span>
        </div>
      </div>
      
      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("emergency_travel", "Emergency Travel");
        }}
      >
        <div className="ins_white_div">
          <img src={img4} className="ins_prop_img"></img>
          <span className="ins_prop">
            Emergency <br></br>Travel
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹109/-pa*</span>
        </div>
      </div>

      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("loan_protection", "Loan Protection");
        }}
      >
        <div className="ins_white_div">
          <img src={popularTag} className="popularImg"></img>
          <img src={img2} className="ins_prop_img"></img>
          <span className="ins_prop">
            Loan <br></br>Protection
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹799/-pa*</span>
        </div>
      </div>
      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("income", "Income Protection");
        }}
      >
        <div className="ins_white_div">
          <img src={popularTag} className="popularImg"></img>
          <img src={img3} className="ins_prop_img"></img>
          <span className="ins_prop">
            Income <br></br>Protection
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹200/-pa*</span>
        </div>
      </div>

      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("hospicash", "Hospicash");
        }}
      >
        <div className="ins_white_div">
          <img src={img9} className="ins_prop_img"></img>
          <span className="ins_prop">
             Hospicash
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹979/-pa*</span>
        </div>
      </div>


      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("insurance", "Insurance");
        }}
      >
        <div className="ins_white_div">
          <img src={img4} className="ins_prop_img"></img>
          <span className="ins_prop">
            EMI <br></br>Protect
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹575/-pa*</span>
        </div>
      </div>
      {/* <div className="ins_item" onClick={()=>{handleItemClickWithoutOptions()}}>
        <div className="ins_white_div">
          <img src={img4} className="ins_prop_img"></img>
          <span className="ins_prop">
            Wellness <br></br>Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹330/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClick('M00X291E', 'OTT+ Insurance');
        }}
      >
        <div className="ins_white_div">
          <img src={popularTag} className="popularImg"></img>
          <img src={img5} className="ins_prop_img"></img>
          <span className="ins_prop">
            OTT+ <br></br>Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹555/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClick('J01YQYY7', 'Wellness');
        }}
      >
        <div className="ins_white_div">
          <img src={img4} className="ins_prop_img"></img>
          <span className="ins_prop">Wellness</span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹330/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions('deals', 'Deals+ Insurance');
        }}
      >
        <div className="ins_white_div">
          <img src={img7} className="ins_prop_img"></img>
          <span className="ins_prop">
            Deals+ <br></br>Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹888/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions('pocket', 'Pocket Insurance');
        }}
      >
        <div className="ins_white_div">
          <img src={img8} className="ins_prop_img"></img>
          <span className="ins_prop">
            Pocket <br></br>Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹159/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions('critical', 'Critical Illness Insurance');
        }}
      >
        <div className="ins_white_div">
          <img src={img9} className="ins_prop_img"></img>
          <span className="ins_prop long">
            Critical Illness<br></br> Insurance
          </span>
          <span className="ins_prop short">
            Critical Illness <br></br> Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹464/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions('child', 'Child Education Protection');
        }}
      >
        <div className="ins_white_div">
          <img src={img10} className="ins_prop_img"></img>
          <span className="ins_prop">
            Child Education <br></br>Protection
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹599/-pa*</span>
        </div>
      </div> */}
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions('home', 'Home Protect Insurance');
        }}
      >
        <div className="ins_white_div">
          <img src={img11} className="ins_prop_img"></img>
          <span className="ins_prop">
            Home Protect <br></br>Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹608/-pa*</span>
        </div>
      </div> */}
      <div
        className="ins_item"
        onClick={() => {
          handleItemClick("13FSLC3Q", "Motor Insurance");
        }}
      >
        <div className="ins_white_div">
          <img src={img12} className="ins_prop_img"></img>
          <span className="ins_prop">
            Motor <br></br>Insurance
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹500/-pa*</span>
        </div>
      </div>
      <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions("upi", "UPI Fraud Protection");
        }}
      >
        <div className="ins_white_div">
          <img src={img13} className="ins_prop_img"></img>
          <span className="ins_prop">
            UPI Fraud <br></br>Protection
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹99/-pa*</span>
        </div>
      </div>
      {/* <div
        className="ins_item"
        onClick={() => {
          handleItemClickWithoutOptions('device', 'Device Protection');
        }}
      >
        <div className="ins_white_div">
          <img src={img14} className="ins_prop_img"></img>
          <span className="ins_prop long">
            Device<br></br>Protection
          </span>
          <span className="ins_prop short">
            Device <br></br>Protection
          </span>
        </div>
        <div className="ins_price_div">
          <span className="ins_rupees">₹699/-pa*</span>
        </div>
      </div> */}
    </div>
  );
}

export default InsuranceItems;
