/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_GRIP_BONDS,
  GET_ALL_GRIP_BONDS_FAILURE,
  GET_ALL_GRIP_BONDS_SUCCESS,
  GET_BOND_DETAILS,
  GET_BOND_DETAILS_FAILURE,
  GET_BOND_DETAILS_SUCCESS,
  GET_GRIP_REDIRECTION_LINK,
  GET_GRIP_REDIRECTION_LINK_FAILURE,
  GET_GRIP_REDIRECTION_LINK_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_USER_CONSENT_STATUS,
  GET_USER_CONSENT_STATUS_FAILURE,
  GET_USER_CONSENT_STATUS_SUCCESS,
  REDEEM_NOW,
  REDEEM_NOW_FAILURE,
  REDEEM_NOW_SUCCESS,
  REGISTER_USER_TO_GRIP,
  REGISTER_USER_TO_GRIP_FAILURE,
  REGISTER_USER_TO_GRIP_SUCCESS,
  YUBI_REDIRECT,
  YUBI_REDIRECT_FAILURE,
  YUBI_REDIRECT_SUCCESS,
  GET_WEALTH_WAITLIST,
  GET_WEALTH_WAITLIST_FAILURE,
  GET_WEALTH_WAITLIST_SUCCESS,
  ADD_WEALTH_WAITLIST,
  ADD_WEALTH_WAITLIST_FAILURE,
  ADD_WEALTH_WAITLIST_SUCCESS,
  VERIFY_ACCOUNT_PAGE_SHOWN,
  JOURNEY_END_PAGE_SHOWN,
  USER_KYC_MODAL_SHOWN,
  OFFER_BANNER_SHOWN,
  SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT,
  GET_BONDS_LIST_SUCCESS,
  GET_BONDS_LIST_FAILURE,
  GENERATE_WEALTH_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  GET_PARTNERS_DETAILS,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_FAILURE,
  VERIFY_WEALTH_OTP,
  WEALTH_VERIFY_OTP_SUCCESS,
  WEALTH_VERIFY_OTP_FAILURE,
  GET_WEALTH_OFFERS,
  GET_WEALTH_OFFERS_SUCCESS,
  GET_WEALTH_OFFERS_FAILURE,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST_FAILURE,
  GET_BANK_LIST,
  GET_BANK_ADDRESS_SUCCESS,
  GET_BANK_ADDRESS_FAILURE,
  GET_BANK_ADDRESS,
  TRIGGER_PANNY_DROP_SUCCESS,
  TRIGGER_PANNY_DROP_FAILURE,
  TRIGGER_PANNY_DROP,
  SET_OFFER_BANNER_APPEARANCE_COUNT,
  SET_JOURNEY_END_PAGE_APPEARANCE_COUNT,
  SET_USER_KYC_MODAL_APPEARANCE_COUNT
} from "./actionTypes";
import {
  getAllGripBondsApiCall,
  getBondDetailsApiCall,
  getOrdersApiCall,
  getRedirectionLinkApiCall,
  redeemNowApiCall,
  registerUserToGripApiCall,
  userConsentStatusApiCall,
  yubiRedirectApiCall,
  getWealthWaitlistApiCall,
  addWealthWaitlistApiCall,
  getBondsListApiCall,
  generateWealthOtpApiCall,
  getPartnerDetailsApiCall,
  verifyOtpApiCall,
  getWealthOffersApiCall,
  GetBankListApiCall,
  GetBankAddressApiCall,
  TriggerPannyDropApiCall
} from "./services";
import { SEND_EVENT_TO_CLEVERTAP } from "../../../utils/constant";
import { insideStashfinApp } from "../../../components/sharedComponents/bridge/bridge";

const STASHFIN_URL = process.env.REACT_APP_STASHFIN_API_URL;

const commonError = "Something went wrong, please try again later";
const stateValue = (state) => state.WealthReducer;

function* getUserConsentStatus() {
  try {
    const response = yield call(userConsentStatusApiCall);
    if (response.status === 200) {
      yield put({ type: GET_USER_CONSENT_STATUS_SUCCESS, payload: response.data.data });
      const wealthWaitlist = yield call(getWealthWaitlistApiCall);
      if (wealthWaitlist.status === 200) {
        yield put({ type: GET_WEALTH_WAITLIST_SUCCESS, payload: wealthWaitlist?.data?.data });
        const bondsListResponse = yield call(getBondsListApiCall);
        if (bondsListResponse.status === 200) {
          yield put({ type: GET_BONDS_LIST_SUCCESS, payload: bondsListResponse?.data?.data });
          const wealthOfferResponse = yield call(getWealthOffersApiCall);
          if (wealthOfferResponse.status === 200) {
            yield put({ type: GET_WEALTH_OFFERS_SUCCESS, payload: wealthOfferResponse?.data?.offers });
          } else if (wealthOfferResponse.status === 401) {
            window.location.assign(`${STASHFIN_URL}wealth/login`);
          } else {
            yield put({
              type: GET_WEALTH_OFFERS_FAILURE,
              payload: {
                error:
                  wealthOfferResponse?.data?.error_message ||
                  wealthOfferResponse?.data?.errorMessage ||
                  wealthOfferResponse?.data?.message ||
                  commonError
              }
            });
          }
        } else if (response.status === 401) {
          window.location.assign(`${STASHFIN_URL}wealth/login`);
        } else {
          yield put({
            type: GET_BONDS_LIST_FAILURE,
            payload: {
              error:
                bondsListResponse?.data?.error_message ||
                bondsListResponse?.data?.errorMessage ||
                bondsListResponse?.data?.message ||
                commonError
            }
          });
        }
      } else if (response.status === 401) {
        window.location.assign(`${STASHFIN_URL}wealth/login`);
      } else {
        yield put({
          type: GET_WEALTH_WAITLIST_FAILURE,
          payload: {
            error:
              wealthWaitlist?.data?.error_message ||
              wealthWaitlist?.data?.errorMessage ||
              wealthWaitlist?.data?.message ||
              commonError
          }
        });
      }
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GET_USER_CONSENT_STATUS_FAILURE,
        payload: {
          error: response?.data?.error_message || response?.data?.errorMessage || response?.data?.message || commonError
        }
      });
    }
  } catch (err) {
    yield put({ type: GET_USER_CONSENT_STATUS_FAILURE, payload: { error: commonError } });
  }
}

function* registerUserToGrip({ payload }) {
  const { userDetails } = yield select(stateValue);
  try {
    const response = yield call(registerUserToGripApiCall, { payload, userDetails });
    if (response.status === 200 && (response.data.status === "SUCCESS" || response.data.status === "SUCEESS")) {
      yield put({ type: REGISTER_USER_TO_GRIP_SUCCESS, payload: true });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: REGISTER_USER_TO_GRIP_FAILURE,
        payload: {
          error: response?.data?.error_message || response?.data?.errorMessage || response?.data?.message || commonError
        }
      });
    }
  } catch (err) {
    yield put({ type: REGISTER_USER_TO_GRIP_FAILURE, payload: { error: commonError } });
  }
}

function* getAllGripBonds() {
  try {
    const response = yield call(getAllGripBondsApiCall);
    if (response.status === 200) {
      yield put({ type: GET_ALL_GRIP_BONDS_SUCCESS, payload: response?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_ALL_GRIP_BONDS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    } else {
      yield put({ type: GET_ALL_GRIP_BONDS_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_ALL_GRIP_BONDS_FAILURE, payload: { error: commonError } });
  }
}

function* getBondDetails() {
  const { bondId } = yield select(stateValue);
  try {
    const response = yield call(getBondDetailsApiCall, { bondId });
    if (response.status === 200) {
      yield put({
        type: GET_BOND_DETAILS_SUCCESS,
        payload: [...(response?.data?.msg?.assetInfo || []), ...(response?.data?.msg?.partnersInfo || [])]
      });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_BOND_DETAILS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    } else {
      yield put({ type: GET_BOND_DETAILS_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_BOND_DETAILS_FAILURE, payload: { error: commonError } });
  }
}

function* getRedirectionLink({ payload }) {
  const { bondId } = yield select(stateValue);
  const assetId = payload || bondId;
  try {
    const response = yield call(getRedirectionLinkApiCall, { bondId: assetId });
    if (response.status === 200 && response?.data?.data?.redirectUrl) {
      const link = response?.data?.data?.redirectUrl ?? "";
      yield put({ type: GET_GRIP_REDIRECTION_LINK_SUCCESS, payload: link });
      if (link !== "") {
        if (insideStashfinApp()) {
          window.location.href = link;
        } else {
          window.open(link, "_blank");
        }
      }
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else if (response?.status > 200 && response?.status < 500) {
      yield put({
        type: GET_GRIP_REDIRECTION_LINK_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    } else {
      yield put({
        type: GET_GRIP_REDIRECTION_LINK_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GET_GRIP_REDIRECTION_LINK_FAILURE, payload: { error: commonError } });
  }
}

function* getOrders() {
  try {
    const response = yield call(getOrdersApiCall);
    if (response?.status === 200) {
      yield put({ type: GET_ORDERS_SUCCESS, payload: response?.data?.data?.orders ?? [] });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: GET_ORDERS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    } else {
      yield put({ type: GET_ORDERS_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: GET_ORDERS_FAILURE, payload: { error: commonError } });
  }
}

function* redeemNow({ payload }) {
  try {
    const response = yield call(redeemNowApiCall, { payload });
    if (response?.status === 200) {
      yield put({ type: REDEEM_NOW_SUCCESS, payload: true });
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: REDEEM_NOW_FAILURE,
        payload: { error: response?.data?.error || commonError, status: false }
      });
    } else {
      yield put({ type: REDEEM_NOW_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: REDEEM_NOW_FAILURE, payload: { error: commonError } });
  }
}

function* getRedirectionLinkForYubi() {
  try {
    const response = yield call(yubiRedirectApiCall);
    if (response?.status === 200) {
      const link = response?.data?.data?.redirectUrl ?? "";
      yield put({ type: YUBI_REDIRECT_SUCCESS, payload: link });
      if (link !== "") {
        window.location.href = link;
      }
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else if (response.status > 200 && response.status < 500) {
      yield put({
        type: YUBI_REDIRECT_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    } else {
      yield put({ type: YUBI_REDIRECT_FAILURE, payload: { error: commonError } });
    }
  } catch (err) {
    yield put({ type: YUBI_REDIRECT_FAILURE, payload: { error: commonError } });
  }
}

function* getWealthWaitlist() {
  try {
    const response = yield call(getWealthWaitlistApiCall);
    if (response.status === 200) {
      yield put({ type: GET_WEALTH_WAITLIST_SUCCESS, payload: response?.data?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GET_WEALTH_WAITLIST_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GET_WEALTH_WAITLIST_FAILURE, payload: { error: commonError } });
  }
}

function* addWealthWaitlist({ payload }) {
  try {
    const response = yield call(addWealthWaitlistApiCall, { payload });
    if (response.status === 200) {
      yield put({ type: ADD_WEALTH_WAITLIST_SUCCESS, payload: response?.data?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: ADD_WEALTH_WAITLIST_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: ADD_WEALTH_WAITLIST_FAILURE, payload: { error: commonError } });
  }
}

function* handleVerifyAccountPageShown({ payload }) {
  const { verifyAccountPageAppearanceCount } = yield select(stateValue);
  const newCount = verifyAccountPageAppearanceCount + 1;
  yield put({ type: SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT, payload: newCount });
  yield put({
    type: SEND_EVENT_TO_CLEVERTAP,
    payload: {
      ...payload,
      eventData: { ...payload.eventData, event_property: { ...payload.eventData.event_property, Frequency: newCount } }
    }
  });
}

function* handleJourneyEndPageShown({ payload }) {
  const { journeyEndPageAppearanceCount } = yield select(stateValue);
  const newCount = journeyEndPageAppearanceCount + 1;
  yield put({ type: SET_JOURNEY_END_PAGE_APPEARANCE_COUNT, payload: newCount });
  yield put({
    type: SEND_EVENT_TO_CLEVERTAP,
    payload: {
      ...payload,
      eventData: { ...payload.eventData, event_property: { ...payload.eventData.event_property, Frequency: newCount } }
    }
  });
}

function* handleUserKycModalShown({ payload }) {
  const { userKycModalAppearanceCount } = yield select(stateValue);
  const newCount = userKycModalAppearanceCount + 1;
  yield put({ type: SET_USER_KYC_MODAL_APPEARANCE_COUNT, payload: newCount });
  yield put({
    type: SEND_EVENT_TO_CLEVERTAP,
    payload: {
      ...payload,
      eventData: { ...payload.eventData, event_property: { ...payload.eventData.event_property, Frequency: newCount } }
    }
  });
}

function* handleOfferBannerShown({ payload }) {
  const { offerBannerAppearanceCount } = yield select(stateValue);
  const newCount = offerBannerAppearanceCount + 1;
  yield put({ type: SET_OFFER_BANNER_APPEARANCE_COUNT, payload: newCount });
  yield put({
    type: SEND_EVENT_TO_CLEVERTAP,
    payload: {
      ...payload,
      eventData: { ...payload.eventData, event_property: { ...payload.eventData.event_property, Frequency: newCount } }
    }
  });
}

function* handleGenerateWealthOtp({ payload }) {
  try {
    const response = yield call(generateWealthOtpApiCall, { payload });
    if (response.status === 200) {
      yield put({ type: GENERATE_OTP_SUCCESS, payload: response?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GENERATE_OTP_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GENERATE_OTP_FAILURE, payload: { error: commonError } });
  }
}

function* getPartnerDetails() {
  try {
    const response = yield call(getPartnerDetailsApiCall);
    if (response.status === 200) {
      yield put({ type: GET_PARTNERS_SUCCESS, payload: response?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GET_PARTNERS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GET_PARTNERS_FAILURE, payload: { error: commonError } });
  }
}

function* verifyWealthOtp({ payload }) {
  try {
    const response = yield call(verifyOtpApiCall, { payload });
    if (response.status === 200) {
      yield put({ type: WEALTH_VERIFY_OTP_SUCCESS, payload: response?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: WEALTH_VERIFY_OTP_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: WEALTH_VERIFY_OTP_FAILURE, payload: { error: commonError } });
  }
}

function* getWealthOffers() {
  try {
    const response = yield call(getWealthOffersApiCall);
    if (response.status === 200) {
      yield put({ type: GET_WEALTH_OFFERS_SUCCESS, payload: response?.data?.offers });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GET_WEALTH_OFFERS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GET_WEALTH_OFFERS_FAILURE, payload: { error: commonError } });
  }
}

function* GetBankList() {
  try {
    const response = yield call(GetBankListApiCall);
    if (response.status === 200) {
      yield put({ type: GET_BANK_LIST_SUCCESS, payload: response?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GET_BANK_LIST_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GET_BANK_LIST_FAILURE, payload: { error: commonError } });
  }
}

function* GetBankAddress({ payload }) {
  try {
    const response = yield call(GetBankAddressApiCall, { payload });
    if (response.status === 200) {
      yield put({ type: GET_BANK_ADDRESS_SUCCESS, payload: response?.data });
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: GET_BANK_ADDRESS_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: GET_BANK_ADDRESS_FAILURE, payload: { error: commonError } });
  }
}

function* TriggerPannyDrop({ payload }) {
  try {
    const { clevertapData, ...rest } = payload;
    const response = yield call(TriggerPannyDropApiCall, rest);
    yield put({
      type: SEND_EVENT_TO_CLEVERTAP,
      payload: {
        ...clevertapData,
        eventData: {
          ...clevertapData.eventData,
          event_property: {
            ...clevertapData.eventData.event_property,
            "Error shown": response?.data?.error_message || response?.data?.message || "",
            "Error received": response?.data?.error_message || response?.data?.message || ""
          }
        }
      }
    });
    if (response.status === 200) {
      debugger;
      yield put({ type: TRIGGER_PANNY_DROP_SUCCESS, payload: response?.data });
      if(response.data.success !== false) {
        window.location.assign(`${STASHFIN_URL}wealth/pd-success`);
      }
      else {
        yield put({
          type: TRIGGER_PANNY_DROP_FAILURE,
          payload: { error: response?.data?.success || response?.data?.success || commonError }
        });
      }
    } else if (response.status === 401) {
      window.location.assign(`${STASHFIN_URL}wealth/login`);
    } else {
      yield put({
        type: TRIGGER_PANNY_DROP_FAILURE,
        payload: { error: response?.data?.error_message || response?.data?.message || commonError }
      });
    }
  } catch (err) {
    yield put({ type: TRIGGER_PANNY_DROP_FAILURE, payload: { error: commonError } });
  }
}

export default function* WealthSaga() {
  yield takeLatest(GET_USER_CONSENT_STATUS, getUserConsentStatus);
  yield takeLatest(REGISTER_USER_TO_GRIP, registerUserToGrip);
  yield takeLatest(GET_ALL_GRIP_BONDS, getAllGripBonds);
  yield takeLatest(GET_BOND_DETAILS, getBondDetails);
  yield takeLatest(GET_GRIP_REDIRECTION_LINK, getRedirectionLink);
  yield takeLatest(GET_ORDERS, getOrders);
  yield takeLatest(REDEEM_NOW, redeemNow);
  yield takeLatest(YUBI_REDIRECT, getRedirectionLinkForYubi);
  yield takeLatest(GET_WEALTH_WAITLIST, getWealthWaitlist);
  yield takeLatest(ADD_WEALTH_WAITLIST, addWealthWaitlist);
  yield takeLatest(VERIFY_ACCOUNT_PAGE_SHOWN, handleVerifyAccountPageShown);
  yield takeLatest(JOURNEY_END_PAGE_SHOWN, handleJourneyEndPageShown);
  yield takeLatest(USER_KYC_MODAL_SHOWN, handleUserKycModalShown);
  yield takeLatest(OFFER_BANNER_SHOWN, handleOfferBannerShown);
  yield takeLatest(GENERATE_WEALTH_OTP, handleGenerateWealthOtp);
  yield takeLatest(GET_PARTNERS_DETAILS, getPartnerDetails);
  yield takeLatest(VERIFY_WEALTH_OTP, verifyWealthOtp);
  yield takeLatest(GET_WEALTH_OFFERS, getWealthOffers);
  yield takeLatest(GET_BANK_LIST, GetBankList);
  yield takeLatest(GET_BANK_ADDRESS, GetBankAddress);
  yield takeLatest(TRIGGER_PANNY_DROP, TriggerPannyDrop);
}
