/*eslint-disable*/
import {
  GET_ALL_GRIP_BONDS,
  GET_ALL_GRIP_BONDS_FAILURE,
  GET_ALL_GRIP_BONDS_SUCCESS,
  GET_BOND_DETAILS,
  GET_BOND_DETAILS_FAILURE,
  GET_BOND_DETAILS_SUCCESS,
  GET_GRIP_REDIRECTION_LINK,
  GET_GRIP_REDIRECTION_LINK_FAILURE,
  GET_GRIP_REDIRECTION_LINK_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAILURE,
  GET_ORDERS_SUCCESS,
  GET_USER_CONSENT_STATUS,
  GET_USER_CONSENT_STATUS_FAILURE,
  GET_USER_CONSENT_STATUS_SUCCESS,
  GET_WEALTH_WAITLIST,
  GET_WEALTH_WAITLIST_FAILURE,
  GET_WEALTH_WAITLIST_SUCCESS,
  ADD_WEALTH_WAITLIST,
  ADD_WEALTH_WAITLIST_FAILURE,
  ADD_WEALTH_WAITLIST_SUCCESS,
  REDEEM_NOW,
  REDEEM_NOW_FAILURE,
  REDEEM_NOW_SUCCESS,
  REGISTER_USER_TO_GRIP,
  REGISTER_USER_TO_GRIP_FAILURE,
  REGISTER_USER_TO_GRIP_SUCCESS,
  RESET_REDEEM,
  SET_BOND_DETAILS,
  SET_CURRENT_SCREEN,
  SET_ORDERS,
  SET_REGISTERED_ON_GRIP,
  SET_REGISTERED_ON_YUBI,
  CLEAR_ERROR,
  PRIORITY_LIST_FORM_RESPONSES,
  SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT,
  SET_JOURNEY_END_PAGE_APPEARANCE_COUNT,
  SET_USER_KYC_MODAL_APPEARANCE_COUNT,
  SET_OFFER_BANNER_APPEARANCE_COUNT,
  GET_BONDS_LIST,
  GET_BONDS_LIST_FAILURE,
  GET_BONDS_LIST_SUCCESS,
  SET_BOND_ASSET_ID,
  GENERATE_WEALTH_OTP,
  GENERATE_OTP_SUCCESS,
  GENERATE_OTP_FAILURE,
  RESET_WEALTH_OTP,
  GET_PARTNERS_DETAILS,
  GET_PARTNERS_SUCCESS,
  SET_LOADER_FALSE,
  VERIFY_WEALTH_OTP,
  WEALTH_VERIFY_OTP_SUCCESS,
  WEALTH_VERIFY_OTP_FAILURE,
  RESET_VERIFY_OTP_DATA,
  SET_SELECTED_BOND,
  GET_PARTNERS_FAILURE,
  GET_WEALTH_OFFERS,
  GET_WEALTH_OFFERS_SUCCESS,
  GET_WEALTH_OFFERS_FAILURE,
  GET_BANK_LIST_SUCCESS,
  GET_BANK_LIST,
  GET_BANK_LIST_FAILURE,
  GET_BANK_ADDRESS_SUCCESS,
  GET_BANK_ADDRESS_FAILURE,
  TRIGGER_PANNY_DROP_FAILURE,
  TRIGGER_PANNY_DROP_SUCCESS,
  TRIGGER_PANNY_DROP,
  GET_BANK_ADDRESS
} from "./actionTypes";

const initialState = {
  currentScreen: "",
  userDetails: {},
  userDetailsError: "",
  userRegisteredToGrip: false,
  userRegisteredToYubi: false,
  errorInRegisteringUserToGrip: "",
  assetsWithCategoriesList: [],
  userKycDone: false,
  bondListError: "",
  bondId: "",
  bondDetailsFromClickedBond: {},
  bondDetails: {},
  bondDetailsError: "",
  redirectionLink: "",
  redirectionError: "",
  myOrders: "",
  myOrdersError: "",
  loader: true,
  redeemStatus: "",
  redeemError: "",
  wealthWaitlistData: {},
  wealthWaitlistError: "",
  successInAddingWaitlist: false,
  errorInAddingWaitlist: "",
  waitlistResponseReceived: false,
  priorityListFormResponses: {},
  bondsList: [],
  bondsListError: "",
  verifyAccountPageAppearanceCount: 0,
  journeyEndPageAppearanceCount: 1,
  userKycModalAppearanceCount: 1,
  offerBannerAppearanceCount: 1,
  bondAssetId: "",
  otpGenerated: false,
  otpData: {},
  partnerDetails: {},
  verifyOtpFailureData: {},
  verifyOtpSuccessData: {},
  selectedBond: {},
  partnerDetailApiErr: "",
  offers: [],
  offersError: "",
  bankList: [],
  bankListFailureData: {},
  bankAddressData: null,
  bankAddressDataFailure: null,
  pannyDropData: null,
  pannyDropFailure: null
};

const WealthReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload
      };
    case GET_USER_CONSENT_STATUS:
      return {
        ...state,
        loader: true
      };
    case GET_USER_CONSENT_STATUS_SUCCESS:
      return {
        ...state,
        userDetails: payload
      };
    case GET_USER_CONSENT_STATUS_FAILURE:
      return {
        ...state,
        userDetailsError: payload.error,
        loader: false
      };
    case REGISTER_USER_TO_GRIP:
      return {
        ...state,
        userBasicDetails: payload,
        loader: true
      };
    case REGISTER_USER_TO_GRIP_SUCCESS:
      return {
        ...state,
        userRegisteredToGrip: payload,
        loader: false
      };
    case REGISTER_USER_TO_GRIP_FAILURE:
      return {
        ...state,
        errorInRegisteringUserToGrip: payload.error,
        loader: false
      };
    case GET_ALL_GRIP_BONDS:
      return {
        ...state,
        loader: true
      };
    case GET_ALL_GRIP_BONDS_SUCCESS:
      return {
        ...state,
        assetsWithCategoriesList: payload.data.assets,
        userKycDone: payload.data.userKycStatus && payload.data.userKycStatus !== "",
        loader: false
      };
    case GET_ALL_GRIP_BONDS_FAILURE:
      return {
        ...state,
        bondListError: payload.error,
        loader: false
      };
    case GET_BOND_DETAILS:
      return {
        ...state,
        bondId: payload?.assetID,
        bondDetailsFromClickedBond: payload,
        loader: true
      };
    case GET_BOND_DETAILS_SUCCESS:
      return {
        ...state,
        bondDetails: payload,
        loader: false
      };
    case GET_BOND_DETAILS_FAILURE:
      return {
        ...state,
        bondDetailsError: payload.error,
        loader: false
      };
    case SET_BOND_DETAILS:
      return {
        ...state,
        bondId: "",
        bondDetailsFromClickedBond: {},
        bondDetails: {}
      };
    case GET_GRIP_REDIRECTION_LINK:
      return {
        ...state,
        loader: true
      };
    case GET_GRIP_REDIRECTION_LINK_SUCCESS:
      return {
        ...state,
        redirectionLink: payload
      };
    case GET_GRIP_REDIRECTION_LINK_FAILURE:
      return {
        ...state,
        redirectionError: payload.error,
        loader: false
      };
    case GET_ORDERS:
      return {
        ...state,
        loader: true
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        myOrders: payload,
        loader: false
      };
    case GET_ORDERS_FAILURE:
      return {
        ...state,
        myOrdersError: payload.error,
        loader: false
      };
    case SET_ORDERS:
      return {
        ...state,
        myOrders: ""
      };

    case REDEEM_NOW:
      return {
        ...state,
        loader: true
      };
    case REDEEM_NOW_SUCCESS:
      return {
        ...state,
        redeemStatus: payload,
        loader: false
      };
    case REDEEM_NOW_FAILURE:
      return {
        ...state,
        redeemStatus: payload.status,
        redeemError: payload.error,
        loader: false
      };
    case RESET_REDEEM:
      return {
        ...state,
        redeemStatus: "",
        redeemError: "",
        loader: false
      };
    case GET_WEALTH_WAITLIST:
      return {
        ...state,
        loader: true
      };
    case GET_WEALTH_WAITLIST_SUCCESS:
      return {
        ...state,
        wealthWaitlistData: payload,
        waitlistResponseReceived: true
      };
    case GET_WEALTH_WAITLIST_FAILURE:
      return {
        ...state,
        wealthWaitlistError: payload.error,
        loader: false
      };
    case ADD_WEALTH_WAITLIST:
      return {
        ...state,
        loader: true
      };
    case ADD_WEALTH_WAITLIST_SUCCESS:
      return {
        ...state,
        successInAddingWaitlist: true,
        loader: false
      };
    case ADD_WEALTH_WAITLIST_FAILURE:
      return {
        ...state,
        loader: false,
        errorInAddingWaitlist: payload.error
      };
    case CLEAR_ERROR:
      return {
        ...state,
        userDetailsError: "",
        errorInAddingWaitlist: "",
        wealthWaitlistError: "",
        errorInRegisteringUserToGrip: "",
        redirectionError: "",
        bondsListError: "",
        otpData: {}
      };
    case PRIORITY_LIST_FORM_RESPONSES:
      return {
        ...state,
        priorityListFormResponses: payload
      };
    case SET_REGISTERED_ON_GRIP:
      return {
        ...state,
        isRegisteredOnGrip: payload
      };
    case SET_REGISTERED_ON_YUBI:
      return {
        ...state,
        isRegisteredOnYubi: payload
      };
    case SET_VERIFY_ACCOUNT_PAGE_APPEARANCE_COUNT:
      return {
        ...state,
        verifyAccountPageAppearanceCount: payload
      };

    case SET_JOURNEY_END_PAGE_APPEARANCE_COUNT:
      return {
        ...state,
        journeyEndPageAppearanceCount: payload
      };

    case SET_USER_KYC_MODAL_APPEARANCE_COUNT:
      return {
        ...state,
        userKycModalAppearanceCount: payload
      };

    case SET_OFFER_BANNER_APPEARANCE_COUNT:
      return {
        ...state,
        offerBannerAppearanceCount: payload
      };

    case GET_BONDS_LIST:
      return {
        ...state,
        loader: true
      };
    case GET_BONDS_LIST_SUCCESS:
      return {
        ...state,
        bondsList: payload,
      };
    case GET_BONDS_LIST_FAILURE:
      return {
        ...state,
        bondsListError: payload.error,
        loader: false
      };
    case SET_BOND_ASSET_ID:
      return {
        ...state,
        bondAssetId: payload
      };
    case GENERATE_WEALTH_OTP:
      return {
        ...state,
        loader: true
      };
    case GENERATE_OTP_SUCCESS:
      return {
        ...state,
        loader: false,
        otpGenerated: true,
        otpData: payload
      };
    case GENERATE_OTP_FAILURE:
      return {
        ...state,
        loader: false,
        otpGenerated: false,
        otpData: payload
      };
    case RESET_WEALTH_OTP:
      return {
        ...state,
        otpGenerated: false,
        otpData: {}
      };
    case GET_PARTNERS_DETAILS:
      return {
        ...state,
        loader: true
      };
    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        loader: false,
        partnerDetails: payload
      };
    case GET_PARTNERS_FAILURE:
      return {
        ...state,
        loader: false,
        partnerDetailApiErr: payload
      };
    case SET_LOADER_FALSE:
      return {
        ...state,
        loader: false
      };
    case VERIFY_WEALTH_OTP:
      return {
        ...state,
        loader: true
      };
    case WEALTH_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loader: false,
        verifyOtpSuccessData: payload
      };
    case WEALTH_VERIFY_OTP_FAILURE:
      return {
        ...state,
        loader: false,
        verifyOtpFailureData: payload
      };
    case RESET_VERIFY_OTP_DATA:
      return {
        ...state,
        verifyOtpFailureData: {},
        verifyOtpSuccessData: {}
      };

    case SET_SELECTED_BOND:
      return {
        ...state,
        selectedBond: payload
      };
    case GET_BANK_LIST:
      return {
        ...state,
        loader: true
      };
    case GET_BANK_LIST_SUCCESS:
      return {
        ...state,
        loader: false,
        bankList: payload
      };
    case GET_BANK_LIST_FAILURE:
      return {
        ...state,
        loader: false,
        bankListFailureData: payload
      };
    case GET_BANK_ADDRESS:
      return {
        ...state,
        loader: true
      };
    case GET_BANK_ADDRESS_SUCCESS:
      return {
        ...state,
        loader: false,
        bankAddressData: payload
      };
    case GET_BANK_ADDRESS_FAILURE:
      return {
        ...state,
        loader: false,
        bankAddressDataFailure: payload
      };
    case TRIGGER_PANNY_DROP:
      return {
        ...state,
        loader: true
      };
    case TRIGGER_PANNY_DROP_SUCCESS:
      return {
        ...state,
        loader: false,
        pannyDropData: payload
      };
    case TRIGGER_PANNY_DROP_FAILURE:
      return {
        ...state,
        loader: false,
        pannyDropFailure: payload
      };

    case GET_WEALTH_OFFERS:
      return {
        ...state,
        loader: true
      };

    case GET_WEALTH_OFFERS_SUCCESS:
      return {
        ...state,
        loader: false,
        offers: payload
      };

    case GET_WEALTH_OFFERS_FAILURE:
      return {
        ...state,
        loader: false,
        offersError: payload.error
      };

    default:
      return state;
  }
};
export default WealthReducer;
