export function navigateToPage(payload) {
  if (typeof cbBridge !== 'undefined') {
    /* eslint-disable no-undef */
    cbBridge.navigateToPage(JSON.stringify(payload));
    /* eslint-enable no-undef */
  } else {
    console.error('cbBridge object is not defined [navigateToPage]');
  }
}

export function initiatePayment(payload) {
  if (typeof cbBridge !== 'undefined') {
    /* eslint-disable no-undef */
    cbBridge.initiatePayment(JSON.stringify(payload));
    /* eslint-enable no-undef */
  } else {
    console.error('cbBridge object is not defined [initiatePayment]');
  }
}

export function sendFirebaseEvent(eventName, payload) {
  if (typeof cbBridge !== 'undefined') {
    /* eslint-disable no-undef */
    cbBridge.sendAnalytics(eventName, JSON.stringify(payload));
    /* eslint-enable no-undef */
  } else {
    console.error('cbBridge object is not defined [sendFirebaseEvent]');
  }
}

export function insideStashfinApp() {
  const deviceId = localStorage.getItem("device_id");
  if (deviceId && deviceId !== null && deviceId !== "") {
    return true;
  }
  return false;
  // if (typeof cbBridge !== "undefined") {
  //   /* eslint-disable no-undef */
  //   return typeof window.cbBridge.isOpenedFromApp === "function";
  //   /* eslint-enable no-undef */
  // } else {
  //   console.error("cbBridge object is not defined [insideStashfinApp]");
  //   return false;
  // }
}
