import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import KeyCoverageDetails from "./keyCoverageDetails";
import ProductDetails from "./productDetails";
import ProductDetailsNew from "./productDetails_New";
import CoverageDetails from "./coverageDetails";
import CoverageDetailsNew from "./coverageDetails_New";
import UnCoverageDetails from "./uncoverageDetails";
import LinksAndDocuments from "./linksAndDocument";
import Details from "./details";
import KeyCoverage from "./keyCoverage";
import PageTitle from "./pageTitle";
import BuyNowCTA from "./buyNowCTA";
import InsuranceFooter from "../Insurance/InsuranceFooter";
import { margin } from "@mui/system";

const InsuranceDetails = ({ payNow }) => {
  const insuranceProductId = window.localStorage.getItem("insuranceProductId");
  const isNewDesign = ["ZBRQ13", "KECU4F", "RSCIFH", "F56D66"].includes(insuranceProductId);

  if (isNewDesign) {
    require("./insurance_New.scss");
  } else {
    require("./insurance.scss");
  }

  if (isNewDesign) {
    return (
      <div>
        <div className="main-container-new" style={{maxWidth: 1200, margin: "0 auto", padding: "0 16px"}}>
          <Helmet>
            <title>Insurance</title>
          </Helmet>
          <PageTitle />
          <ProductDetailsNew />
          <KeyCoverage />
          <Details />
          <CoverageDetailsNew />
          <UnCoverageDetails />
          <LinksAndDocuments />
          <BuyNowCTA payNow={payNow} />
        </div>
        <InsuranceFooter />
      </div>
    );
  }

  return (
    <div>
      <div className="main-container-old" style={{maxWidth: 1200, margin: "0 auto", padding: "0 16px"}}>
        <Helmet>
          <title>Insurance</title>
        </Helmet>
        <ProductDetails payNow={payNow} />
        <KeyCoverageDetails />
        <CoverageDetails />
        <UnCoverageDetails />
        <LinksAndDocuments />
      </div>
      <InsuranceFooter />
    </div>
  );
};

export default InsuranceDetails;
