import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const BASE_URL_V2 = process.env.REACT_APP_BASE_V2_API_URL;
const EQX_URL = process.env.REACT_APP_EQX_API_URL;
const BASE_URL_EQX = process.env.REACT_APP_BASE_EQX_API_URL;

export const preConfigApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/preconfig`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getBondsListApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/all/listed/assets`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const userConsentStatusApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/user/profile`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const registerUserToGripApiCall = async (data) => {
  const { payload, userDetails } = data;
  let body = {
    gatewayId: "grip",
    bondType: "listed",
    consent: "Y"
  };
  if (payload.first_name !== "" && payload.last_name !== "" && payload.email !== "") {
    body = {
      ...body,
      user: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        phone: userDetails?.phone
      }
    };
  }
  try {
    const res = await axios.post(`${EQX_URL}wealth/user/register`, body, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
export const getAllGripBondsApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/asset/list/listed/grip`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getBondDetailsApiCall = async ({ bondId }) => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/asset/listed/grip/${bondId}`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getRedirectionLinkApiCall = async ({ bondId }) => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/user/asset/redirect/listed/grip/${bondId}`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getOrdersApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/user/portfolio/listed/grip`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const redeemNowApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(
      `${BASE_URL}rewards/v1/trackReferral`,
      {
        referralCode: payload,
        product: "wealth",
        productSubType: "default"
      },
      {
        headers: {
          auth_token: `${localStorage.getItem("auth_token")}`,
          device_id: localStorage.getItem("device_id")
        }
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const yubiRedirectApiCall = async () => {
  const res = await axios.get(`${EQX_URL}wealth/user/asset/redirect/listed/yubi/`, {
    headers: {
      auth_token: `${localStorage.getItem("auth_token")}`,
      device_id: `${localStorage.getItem("device_id")}`
    }
  });
  return res;
};

export const getWealthWaitlistApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/waitlist/WEALTH`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const addWealthWaitlistApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(`${EQX_URL}wealth/waitlist/wealth/add`, payload, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const generateWealthOtpApiCall = async ({ payload }) => {
  try {
    const urlEncodedData = new URLSearchParams(payload).toString();
    const res = await axios.post(`${BASE_URL_V2}api`, urlEncodedData, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id"),
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getPartnerDetailsApiCall = async () => {
  try {
    const secret = process.env.REACT_APP_SECRET_PARTNER_WEALTH;
    const res = await axios.get(`${BASE_URL_V2}api/get_partners_details?secret=${secret}&utm_source=${null}`, {});
    return res;
  } catch (err) {
    return err.response;
  }
};

export const verifyOtpApiCall = async ({ payload }) => {
  try {
    const urlEncodedData = new URLSearchParams(payload).toString();
    const res = await axios.post(`${BASE_URL_V2}api`, urlEncodedData, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id"),
        "Content-Type": "application/x-www-form-urlencoded",
        secret: process.env.REACT_APP_SECRET_PARTNER_WEALTH
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getWealthOffersApiCall = async () => {
  try {
    const res = await axios.get(`${EQX_URL}wealth/offers`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const GetBankListApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}customers/v1/journey/bank-list`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id"),
        "Content-Type": "application/x-www-form-urlencoded",
        secret: process.env.REACT_APP_SECRET_PARTNER_WEALTH
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const GetBankAddressApiCall = async ({ payload }) => {
  try {
    const res = await axios.get(`${BASE_URL_EQX}eqxcustomers/validate-ifsc?ifsc=${payload}`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id"),
        "Content-Type": "application/x-www-form-urlencoded",
        secret: process.env.REACT_APP_SECRET_PARTNER_WEALTH
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const TriggerPannyDropApiCall = async (payload) => {
  try {
    // const urlEncodedData = new URLSearchParams(payload).toString();
    const res = await axios.post(`${BASE_URL_EQX}eqxcustomers/trigger-pd`, payload, {
      headers: {
        auth_token: localStorage.getItem("auth_token"),
        device_id: localStorage.getItem("device_id"),
        "Content-Type": "application/json",
        secret: process.env.REACT_APP_SECRET_PARTNER_WEALTH
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
